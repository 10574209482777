@-moz-document url-prefix() {
    
    .horizontal-scrollbar  {
      scrollbar-width: none !important;
    }
  }

.active{
  color: black !important;
  font-weight: bold;
}

.category-links{
  
  color: rgb(173, 173, 173);
  font-weight: bold;
  
}

.category-links:hover {
  cursor: pointer;
}



.takeout-cart::-webkit-scrollbar, .history::-webkit-scrollbar{
  display: none;
}

